import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { LocationProvider } from '@reach/router';
import moment from 'moment';
import momentEs from 'moment/locale/es';
import { AuthProvider } from 'components/Auth/AuthProvider';
import ErrorBoundary from 'components/Error/ErrorBoundary';
import { I18n } from 'components/I18n/I18n';
import { ThemeProvider } from 'components/Theme/ThemeProvider';
import { deviceLog, verifyDeviceConfig, LOG_EVENT_TYPES, PING_LOG_INTERVAL } from 'utils/deviceUtils';
import { AppRouter } from './components/AppRouter';

moment.updateLocale('es', momentEs);

export default function App() {
  React.useEffect(startLogging, []);
  React.useEffect(verifyDeviceConfig, []);

  return (
    <ErrorBoundary>
      <LocationProvider>
        <CssBaseline />
        <ThemeProvider>
          <AuthProvider>
            <I18n>
              <AppRouter />
            </I18n>
          </AuthProvider>
        </ThemeProvider>
      </LocationProvider>
    </ErrorBoundary>
  );
}

let logInterval;
const startLogging = () => {
  deviceLog(LOG_EVENT_TYPES.start);
  if (logInterval) return;
  logInterval = setInterval(() => {
    deviceLog(LOG_EVENT_TYPES.ping);
  }, PING_LOG_INTERVAL);
};
