import React from 'react';
import { Box, Checkbox, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useClient } from 'components/Client/clientHooks';
import { PHASES } from 'components/Visit/VisitProvider';
import { Phase } from './Phase';
import { useVisitForm } from './visitHooks';
import { MAFLOW } from 'components/Client/clientConsts';
import { useWindowDimensions } from 'utils/dom';

export const MaflowFinalText = (props) => {
  const { client } = useClient();
  const { t } = useTranslation();
  const { visitForm } = useVisitForm();
  const [checked, setChecked] = React.useState(false);
  const { height } = useWindowDimensions();
  const classes = useStyles();
  const handleSubmit = () => {
    if (checked) {
      visitForm.setValue('dataProcessing2', checked);
      props.next();
    }
  };

  return (
    <Phase phase={PHASES.maflow} next={handleSubmit} back={props.back} canNext={checked}>
      {client.id === MAFLOW && (
        <Grid item xs container spacing={1}>
          <Grid item xs={12} height={height * 0.8}>
            <Box
              className={classes.background}
              height={height * 0.8}
              maxWidth={1}
              overflow="scroll"
              data-testid={props.pdf.downloadUrl}
            >
              <Typography style={{ fontSize: 17, fontWeight: 'bold' }}>
                POLÍTICA DE SEGURIDAD DE LA INFORMACIÓN PARA LA GESTIÓN DE VISITANTES IT
              </Typography>
              <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>Propósito</Typography>
              <Typography>
                El propósito de esta política es establecer directrices claras para gestionar las visitas de personas
                externas a las instalaciones de TI, asegurando la protección de los activos tecnológicos, la información
                confidencial y la infraestructura de la organización.
              </Typography>
              <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>Alcance</Typography>
              <Typography>
                Esta política aplica a todos los visitantes, contratistas y terceros que ingresen a las áreas
                restringidas de TI, incluidas salas de servidores, laboratorios, oficinas de soporte técnico o cualquier
                área donde se manejen activos críticos.
              </Typography>
              <Typography style={{ fontSize: 17, fontWeight: 'bold' }}>
                POLITICA DE SEGURIDAD DE LA INFORMACION PARA VISITANTES Y TECNICOS TI
              </Typography>
              <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>1. Registro Previo:</Typography>
              <Typography>
                Todos los visitantes deben registrarse previamente indicando el propósito de la visita, su duración y
                los activos que requerirán acceso. El registro debe incluir un documento de identificación válido.
              </Typography>
              <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>2. Confidencialidad:</Typography>
              <Typography>
                Por la presente, acepto que toda la información a la que acceda durante mi visita a las instalaciones de
                Maflow Spain Automotive, incluyendo pero no limitándose a datos, procesos, infraestructura tecnológica,
                comunicaciones y cualquier otro contenido sensible, será tratada como estrictamente confidencial. Me
                comprometo a no divulgar, reproducir ni utilizar dicha información para ningún propósito fuera del
                autorizado por Maflow Spain Automotive, y a cumplir con todas las políticas de seguridad establecidas.
                Reconozco que cualquier incumplimiento de este acuerdo puede resultar en sanciones legales y otras
                medidas que la organización considere necesarias.
              </Typography>
              <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>2. Acceso a las Instalaciones</Typography>
              <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>1. Control de Acceso Físico:</Typography>
              <Typography>
                Los visitantes recibirán una tarjeta o credencial temporal que identificará claramente su estatus como
                visitante. No se permitirá acceso sin acompañamiento por parte de un empleado autorizado.
              </Typography>
              <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>2. Áreas Restringidas:</Typography>
              <Typography>
                El acceso a áreas críticas, como salas de servidores o centros de datos, estará restringido únicamente a
                aquellas personas cuyo propósito lo justifique.
              </Typography>
              <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>3. Uso de Dispositivos</Typography>
              <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>
                1. Prohibición de Dispositivos No Autorizados:
              </Typography>
              <Typography>
                No se permitirá el ingreso de dispositivos electrónicos personales (laptops, discos externos, etc.) a
                menos que se autorice explícitamente.
              </Typography>
              <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>2. Uso de Recursos Corporativos:</Typography>
              <Typography>
                El uso de equipos de la organización estará supervisado y se limitará al propósito específico de la
                visita.
              </Typography>
              <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>3. Prohibición de Grabaciones:</Typography>
              <Typography>
                Queda prohibida la captura de fotografías, videos, o grabaciones de audio dentro de las instalaciones
                sin autorización expresa.
              </Typography>
              <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>4. Monitoreo y Supervisión</Typography>
              <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>1. Acompañamiento Permanente:</Typography>
              <Typography>
                Los visitantes deben ser acompañados por un empleado designado durante toda la duración de su estancia
                en áreas restringidas.
              </Typography>
              <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>2. Registro de Actividades:</Typography>
              <Typography>
                Las actividades realizadas por el visitante deberán ser documentadas, incluyendo el acceso a sistemas,
                información o infraestructura.
              </Typography>
              <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>3. CCTV:</Typography>
              <Typography>Las áreas críticas estarán sujetas a monitoreo mediante cámaras de seguridad.</Typography>
              <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>5. Finalización de la Visita</Typography>
              <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>1. Devolución de Credenciales:</Typography>
              <Typography>
                Al finalizar la visita, el visitante debe devolver la credencial temporal otorgada.
              </Typography>
              <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>2. Revisión de Pertenencias:</Typography>
              <Typography>
                El personal de seguridad puede inspeccionar las pertenencias del visitante al salir para garantizar que
                no se extraiga material no autorizado.
              </Typography>
              <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>3. Reporte de Incidencias:</Typography>
              <Typography>
                Cualquier irregularidad durante la visita deberá ser reportada inmediatamente al equipo de seguridad de
                la información.
              </Typography>
              <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>6. Consecuencias del Incumplimiento</Typography>
              <Typography>
                El incumplimiento de esta política resultará en la terminación inmediata del acceso del visitante y
                podría dar lugar a acciones legales dependiendo de la gravedad de la violación.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} style={{ height: '10%' }}>
            <Box height={'10%'} width={1} display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <Checkbox
                onChange={(e) => {
                  setChecked(e.target.checked);
                }}
                color="primary"
              />
              <Typography>{t('understood2')}</Typography>
            </Box>
          </Grid>
        </Grid>
      )}
    </Phase>
  );
};

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: 'lightgrey',
  },
  activeButton: {
    backgroundColor: 'black',
    color: 'white',
  },
}));
