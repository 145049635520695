import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useClient } from 'components/Client/clientHooks';
import { useValidationSchema } from './visitHooks';
import { MAFLOW } from 'components/Client/clientConsts';

export const VisitContext = React.createContext();

export const VisitProvider = (props) => {
  const { client } = useClient();
  const { schema } = useValidationSchema();
  const [defaultValues] = React.useState(getDefaultFormValues(client));
  const visitForm = useForm({
    defaultValues,
    shouldUnregister: false,
    resolver: yupResolver(schema),
  });
  const [phases, setPhases] = React.useState([]);
  const [phaseIndexes, setPhaseIndexes] = React.useState({});
  const { t } = useTranslation();
  const [errorTypes] = React.useState([
    {
      text: t('requiredField'),
      types: ['required'],
    },
    {
      text: t('formatIncorrect'),
      types: ['format', 'email'],
    },
  ]);

  React.useEffect(() => {
    const isQuestionaryAvailable = !client.questionaryDisabled || !client.showDataQuestionary;
    const availablePhases = [
      PHASES.access,
      PHASES.personalDetails,
      !client.questionaryDisabled && PHASES.questionary,
      client.showDataQuestionary && PHASES.dataProcessing,
      client.rulesFilename && PHASES.rules,
      client.id === MAFLOW && PHASES.maflow,
      PHASES.result,
    ];
    const phases = availablePhases.filter((phase) => phase !== false && phase !== undefined);
    setPhases(phases);
    setPhaseIndexes(
      phases.reduce((result, phaseType, i) => {
        return {
          ...result,
          [phaseType]: i,
        };
      }, {})
    );
  }, [client]);

  return (
    <VisitContext.Provider value={{ visitForm, defaultValues, phases, phaseIndexes, errorTypes }}>
      {props.children}
    </VisitContext.Provider>
  );
};

export const getDefaultFormValues = () => {
  return {
    nif: '',
    photo: '',
    name: '',
    surname: '',
    company: '',
    email: '',
    reason: '',
    department: '',
    comment: '',
    employeeName: '',
    employeeEmail: '',
    employeePhone: '',
    receiveRulesEmail: true,
    dataProcessing: {},
    dataProcessing2: false,
  };
};

export const PHASES = {
  access: 'access',
  personalDetails: 'personalDetails',
  questionary: 'questionary',
  dataProcessing: 'dataProcessing',
  rules: 'rules',
  result: 'result',
  maflow: MAFLOW,
};

export const PREFILL_FILELDS = [
  'photo',
  'name',
  'surname',
  'company',
  'email',
  'reason',
  'department',
  'comment',
  'employeeName',
  'employeeEmail',
  'employeePhone',
];
