import React from 'react';
import { ClientProvider } from 'components/Client/ClientContext';
import { useClient } from 'components/Client/clientHooks';
import { KeyboardProvider } from 'components/CustomKeyboard/KeyboardContext';
import { useCleanCache } from 'components/Device/deviceHooks';
import { Access } from 'components/Visit/Access';
import { DataQuestionary } from 'components/Visit/DataQuestionary';
import { PersonalDetails } from 'components/Visit/PersonalDetails';
import { Questionary } from 'components/Visit/Questionary';
import { Result } from 'components/Visit/Result';
import { Timer } from 'components/Visit/Timer';
import { useRulesPdf, useVisitForm, useVisit } from 'components/Visit/visitHooks';
import { VisitProvider, PHASES, PREFILL_FILELDS } from 'components/Visit/VisitProvider';
import { VisitorRules } from 'components/Visit/VisitRules';
import { MaflowFinalText } from 'components/Visit/MalfowFinalText';
import { deviceLog, LOG_EVENT_TYPES } from 'utils/deviceUtils';

const VisitScreen = () => {
  const { visitForm, defaultValues, phases } = useVisitForm();
  const rulesPdf = useRulesPdf();
  const client = useClient();
  const [phase, setPhase] = React.useState(0);
  const { reset, setValue, getValues } = visitForm;
  const [visit] = useVisit({ debounce: window.Cypress ? 0 : 500 }); // TODO: Move variable to .env
  const cleanCache = useCleanCache();
  const [employeeData, setEmployeeData] = React.useState();

  React.useEffect(() => {
    const fetchData = async () => {
      if (visit && visit.employeeName) {
        const employeeData = await client.queryRef
          .collection('employees')
          .where('name', '==', visit.employeeName)
          .get();
        if (employeeData.docs.length > 0) {
          setEmployeeData(employeeData.docs[0].data());
        }
      }
      return;
    };
    fetchData();
  }, [client.queryRef, visit]);

  React.useEffect(() => {
    deviceLog(LOG_EVENT_TYPES.render, { component: 'VisitScreen' });
  }, []);

  const resetPhase = React.useCallback(() => {
    setPhase(0);
    reset(defaultValues);
    deviceLog(LOG_EVENT_TYPES.resetPhase);
    cleanCache();
  }, [reset, defaultValues, cleanCache]);

  const handleNext = React.useCallback(() => {
    if (phase === phases.length - 1) {
      resetPhase();
    } else {
      setPhase(phase + 1);
    }
  }, [phase, setPhase, phases.length, resetPhase]);

  const handleBack = () => {
    if (phase - 1 === 0) {
      resetPhase();
    } else {
      setPhase(phase - 1);
      visitForm.clearErrors();
    }
  };

  // Prefill visit
  React.useEffect(() => {
    if (!visit) {
      return;
    }
    PREFILL_FILELDS.filter((field) => !!visit[field])
      .filter((field) => defaultValues[field] !== undefined)
      .forEach((field) => {
        if (employeeData) {
          if (field === 'employeeName') {
            setValue(field, employeeData.name);
          } else if (field === 'employeeEmail') {
            setValue(field, employeeData.email);
          } else if (field === 'employeePhone') {
            setValue(field, employeeData.phoneNumber);
          } else {
            setValue(field, visit[field]);
          }
          setEmployeeData(undefined);
        } else {
          if (getValues().employeeName !== '' && field === 'employeeName') return;
          if (getValues().employeeEmail !== '' && field === 'employeeEmail') return;
          if (getValues().employeePhone !== '' && field === 'employeePhone') return;
          setValue(field, visit[field]);
        }
      });
  }, [visit, setValue, defaultValues, employeeData, getValues]);

  return (
    <>
      {phases[phase] === PHASES.access && (
        <KeyboardProvider>
          <Access next={handleNext} />
        </KeyboardProvider>
      )}
      {phases[phase] === PHASES.personalDetails && (
        <KeyboardProvider>
          <PersonalDetails next={handleNext} back={handleBack} />
        </KeyboardProvider>
      )}
      {phases[phase] === PHASES.questionary && (
        <KeyboardProvider>
          <Questionary next={handleNext} back={handleBack} />
        </KeyboardProvider>
      )}
      {phases[phase] === PHASES.dataProcessing && (
        <KeyboardProvider>
          <DataQuestionary next={handleNext} back={handleBack} pdf={rulesPdf} />
        </KeyboardProvider>
      )}
      {phases[phase] === PHASES.rules && <VisitorRules next={handleNext} back={handleBack} pdf={rulesPdf} />}
      {phases[phase] === PHASES.maflow && <MaflowFinalText next={handleNext} back={handleBack} pdf={rulesPdf} />}
      {phases[phase] === PHASES.result && <Result next={handleNext} back={handleBack} rulesPdf={rulesPdf} />}
      {process.env.NODE_ENV !== 'development' && <Timer onReset={resetPhase} />}
    </>
  );
};

const Visit = () => (
  <ClientProvider>
    <VisitProvider>
      <VisitScreen />
    </VisitProvider>
  </ClientProvider>
);
export default Visit;
