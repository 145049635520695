import React from 'react';
import { Box, Checkbox, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Document, pdfjs, Page } from 'react-pdf';
import { useAuth } from 'components/Auth/authHooks';
import { CustomCircularProgress } from 'components/CustomCircularProgress/CustomCircularProgress';
import { useVisitForm } from 'components/Visit/visitHooks';
import { PHASES } from 'components/Visit/VisitProvider';
import { useWindowDimensions } from 'utils/dom';
import { Phase } from './Phase';
import { MAFLOW } from 'components/Client/clientConsts';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const VisitorRules = (props) => {
  const [pagesCount, setPagesCount] = React.useState(0);
  const { visitForm } = useVisitForm();
  const { clientId } = useAuth();
  const [isCheckedUnderstood, setIsCheckedUnderstood] = React.useState(false);
  const { t } = useTranslation();
  const { height } = useWindowDimensions();
  const { next } = props;
  const phaseRef = React.useRef();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const sideButtonsWidth = isXs ? 40 : 2 * 96;

  const onLoadPdf = ({ numPages }) => {
    setPagesCount(numPages);
  };

  React.useEffect(() => {
    if (props.pdf.error) {
      next(); // When PDF cannot be loaded skip this step
    }
  }, [props.pdf.error, next]);
  return (
    <Phase
      phase={PHASES.rules}
      next={props.next}
      back={props.back}
      maxWidth="lg"
      ref={phaseRef}
      canNext={clientId === MAFLOW ? isCheckedUnderstood : true}
      {...(clientId === MAFLOW && { understood: !isCheckedUnderstood })}
    >
      <Grid item xs container spacing={1}>
        <Grid item xs={12}>
          <Box height={height * 0.7} maxWidth={1} overflow="scroll" data-testid={props.pdf.downloadUrl}>
            {props.pdf.isLoading ? (
              <CustomCircularProgress />
            ) : (
              !window.Cypress && ( // Hotfix: react-pdf worker doesn't work in Cypress tests.
                <Document file={props.pdf.raw} onLoadSuccess={onLoadPdf} loading={() => <CustomCircularProgress />}>
                  {!!pagesCount &&
                    [...Array(pagesCount).keys()].map((i) => (
                      <Page
                        key={i + 1}
                        pageNumber={i + 1}
                        loading=""
                        width={phaseRef.current ? phaseRef.current.clientWidth - sideButtonsWidth : 1100}
                      />
                    ))}
                </Document>
              )
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            display={'flex'}
            width={1}
            height={1}
            style={{ backgroundColor: theme.palette.grey[300] }}
            paddingLeft={2}
            flexDirection={'column'}
          >
            <Box>
              <Typography component="span">{t('receiveRulesEmail')}</Typography>
              <Controller
                render={(inputProps) => (
                  <Checkbox
                    {...inputProps}
                    onChange={(e) => inputProps.onChange(e.target.checked)}
                    checked={inputProps.value}
                    disabled={!visitForm.getValues('email')}
                  />
                )}
                name="receiveRulesEmail"
                control={visitForm.control}
              />
            </Box>
            {clientId === MAFLOW && (
              <Box>
                <Typography component="span">{t('understood')}</Typography>
                <Checkbox checked={isCheckedUnderstood} onChange={(e) => setIsCheckedUnderstood(e.target.checked)} />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Phase>
  );
};
