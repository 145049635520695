export const es = {
  translation: {
    'Visits register': 'Registro de visitas',
    yes: 'sí',
    no: 'no',
    next: 'siguiente',
    back: 'volver',
    enter: 'entrar',
    exit: 'salir',
    save: 'Guardar',
    remove: 'Borrar',
    actions: 'Acciones',
    close: 'Cerrar',
    nif: 'DNI',
    nifRequired: 'DNI es obligatorio',
    qrHint: 'Si lo prefieres, registra la visita desde tu propio móvil',
    visitConfirmation: 'Su visita se ha registrado correctamente',
    visitConfirmationError: 'No se ha podido guardar la visita',
    saving: 'Guardando',
    visitor: 'visitante',
    email: 'Correo electrónico',
    reason: 'Motivo de visita',
    department: 'Departamento',
    questionaryTitle: 'indique si en las dos últimas semanas',
    requiredField: 'Campo obligatorio',
    formatIncorrect: 'Formato incorrecto',
    receiveRulesEmail: 'Quiero recibir las reglas de seguridad en mi correo electrónico',
    authorize: 'Autorizar',
    manualExit: 'manual',
    closeVisit: 'Cerrar la visita',
    reprintTicket: 'Imprimir la etiqueta',
    downloadExcel: 'Descargar Excel',
    createVisit: 'Registrar visita',
    nameAndSurname: 'Nombre y apellidos',
    name: 'Nombre',
    surname: 'Apellidos',
    company: 'Empresa',
    employee: 'Persona visitada',
    employees: 'Empleados',
    employeeName: 'Nombre persona visitada',
    employeeEmail: 'Email de la persona visitada',
    employeePhone: 'Teléfono o extensión',
    visitComment: 'Comentario adicional',
    comment: 'Comentario',
    activeVisits: 'Visitas activas',
    visitsToday: 'Visitas hoy',
    clients: 'Clientes',
    goodbye: 'Gracias por su visita',
    goToVisitorsLog: 'Ir al registro de visitas',
    loginAs: 'Logearse',
    logout: 'Cerrar la sesión',
    health: 'Salud',
    exitAuthorized: 'Salida autorizada',
    exitNotAuthorized: 'Su salida no está autorizada',
    exitAuthorizationOptions: 'Opciones para efectuar su salida',
    exitAuthorizationOptionScan: 'La persona visitada debe escanear su tarjeta de empleado',
    exitAuthorizationOptionAdmin: 'Solicite autorización personándose en portería',
    gdprLink:
      'Información sobre Ley Orgánica 3/2018, de Protección de Datos Personales y garantía de los derechos digitales. <1>Leer más<1>.',
    gdprTitle: 'De conformidad con la Ley Orgánica de Protección de Datos de carácter personal, le informamos:',
    gdprCondition1:
      'Que los datos aportados en este formulario, son obligatorios para su autorización y serán incluidos en nuestra Base de Datos para uso exclusivo por el personal autorizado.',
    gdprCondition2:
      'Que está usted accediendo a un centro de trabajo dotado de Video-vigilancia donde se recogen imágenes, pudiendo usted ejecutar los derechos de acceso, rectificación, cancelación y oposición dirigiéndose por escrito al departamento correspondiente.',
    printingTicket: 'Recoja su etiqueta identificativa en ventanilla.',
    placeTicket: 'Pegue la etiqueta en un sitio visible',
    emptyTable: 'No hay ningún registro',
    paginationOf: 'de',
    filterTable: 'Filtrar',
    reset: 'Resetear',
    log: 'Log',
    closeVisits: 'Cerrar visitas',
    closeVisitsConfirm: '¿Está seguro de que desea cerrar las visitas abiertas?',
    understood: 'Entendido',
    questionaryTitleUserData: 'CONSENTIMIENTO EXPRESO DE CESIÓN DE DATOS',
    details: 'Detalles',
    addEmployee: 'Añadir empleado',
    deleteEmployee: 'Borrar empleado',
    editEmployee: 'Editar empleado',
    understood2: 'Entiendo y acepto la Política',
  },
};
