import React from 'react';
import { Box, Container, Grid, Link, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useLocation } from '@reach/router';
import { db } from 'firebase.js';
import QRCode from 'qrcode.react';
import { Controller } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useAuth } from 'components/Auth/authHooks';
import { MainButton } from 'components/Button/MainButton';
import { useClient } from 'components/Client/clientHooks';
import { LAYOUTS } from 'components/CustomKeyboard/CustomKeyboard';
import { useKeyboard } from 'components/CustomKeyboard/keyboardHooks';
import CustomTextField from 'components/CustomTextField/CustomTextField';
import { AppVersion } from 'components/Device/AppVersion';
import { removeSpaces, toUpperCase } from 'components/SpeechRecognition/speechRecognitionUtils';
import * as Dialogs from 'components/Visit/Dialogs';
import { useVisitForm, useVisits, useSendEmails } from 'components/Visit/visitHooks';
import { PREFILL_FILELDS } from 'components/Visit/VisitProvider';
import { getAccessByNif, isExitAuthorized, isInside as getIsInside } from 'components/Visit/visitUtils';
import firebase from 'firebase/compat/app';
import { isObjectEmpty } from 'utils/objectUtils';
import { removeSpecialChars } from 'utils/stringUtils';
import { MAFLOW } from 'components/Client/clientConsts';

let exitOKDialogTimeout;
const DNI_MAX_LENGTH = 30;

export const Access = (props) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { isExternal } = useAuth();
  const { client, getFieldLabel } = useClient();
  const sendEmails = useSendEmails();
  const [visits] = useVisits({ where: { active: true } });
  const { visitForm, defaultValues } = useVisitForm();
  const [dialog, setDialog] = React.useState();
  const nif = visitForm.watch('nif');
  const access = getAccessByNif(visits, nif);
  const theme = useTheme();
  const centeredRef = React.useRef(null);
  const { next } = props;
  const isInside = getIsInside(visits, nif);
  const nifRef = React.useRef();
  const keyboard = useKeyboard({ layout: LAYOUTS.dni });
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const { reset } = keyboard;
  const { setValue } = visitForm;

  const closeDialog = React.useCallback(() => {
    setDialog();
    if (exitOKDialogTimeout) {
      clearTimeout(exitOKDialogTimeout);
    }
  }, []);

  const refocusNif = React.useCallback(() => {
    if (dialog) return;
    if (!nifRef || !nifRef.current) return;
    nifRef.current.focus();
  }, [dialog]);

  React.useEffect(() => {
    // Reset DNI input on mount
    reset();
  }, [reset]);

  // Reset prefilled values on NIF change
  React.useEffect(() => {
    PREFILL_FILELDS.forEach((field) => setValue(field, defaultValues[field]));
  }, [nif, setValue, defaultValues]);

  // Focus DNI always
  React.useEffect(refocusNif, [refocusNif]);

  const handleExit = () => {
    if (isObjectEmpty(client.exitAuthorizations) || isExitAuthorized(visits, nif)) {
      onExitAuthorized();
      const access = getAccessByNif(visits, nif);
      if (client.id === MAFLOW) {
        sendEmails(access, '', true);
      }
    } else {
      setDialog(Dialogs.TYPES.notAuthorised);
    }
  };

  const exit = React.useCallback(() => {
    const nif = visitForm.getValues('nif');
    const access = getAccessByNif(visits, nif);
    visitForm.reset();
    return db
      .collection('clients')
      .doc(client.id)
      .collection('accesses')
      .doc(access.id)
      .update({ exit: firebase.firestore.FieldValue.serverTimestamp() });
  }, [visitForm, visits, client.id]);

  const onExitAuthorized = React.useCallback(async () => {
    await exit();
    setDialog(Dialogs.TYPES.exitOk);
    exitOKDialogTimeout = setTimeout(() => {
      closeDialog();
    }, 2000);
  }, [exit, closeDialog]);

  const printFlags = () => {
    return (
      <Grid container justify="center" alignItems="center" spacing={1}>
        {['es', 'en'].map((language) => (
          <Grid item xs="auto" onClick={() => i18n.changeLanguage(language)} key={language}>
            <img
              src={`${process.env.PUBLIC_URL}/images/flag-${language}.svg`}
              alt={`${language} flag`}
              style={{ opacity: i18n.language === language ? 1 : 0.3, height: isXs ? 45 : 70 }}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <>
      <AppVersion />
      <Container
        style={{
          height: `calc(100vh - ${keyboard.height || 0}px)`,
          paddingTop: theme.spacing(4),
          position: 'relative',
          display: 'flex',
        }}
        maxWidth="sm"
      >
        <Grid container alignItems="flex-start" ref={centeredRef} style={{ alignSelf: 'center' }}>
          {client.theme && client.theme.logo && (
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <img
                src={client.theme.logo}
                alt={`logo ${client.name}`}
                title={`logo ${client.name}`}
                style={{ maxWidth: '100%', maxHeight: 150, marginBottom: theme.spacing(4) }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Box marginY={2}>
              <Typography variant="h4" align="center">
                {t('Visits register')}
              </Typography>
            </Box>
          </Grid>

          <Grid container item xs={12} spacing={1} justify="center">
            <Grid container item xs={12} sm={9} spacing={1}>
              <Grid item xs={12}>
                <Controller
                  name="nif"
                  control={visitForm.control}
                  render={(inputProps) => (
                    <CustomTextField
                      {...inputProps}
                      inputRef={nifRef}
                      label={getFieldLabel('nif', t('nif'))}
                      voiceToText
                      transformations={[removeSpaces, removeSpecialChars, toUpperCase]}
                      InputProps={{ onBlur: refocusNif }}
                      onChange={(value) => {
                        if (value.length > DNI_MAX_LENGTH) return;
                        inputProps.onChange(value);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <MainButton
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={next}
                  style={{ height: 65 }}
                  disableElevation
                  disabled={!nif || isInside}
                >
                  {t('enter')}
                </MainButton>
              </Grid>
              <Grid item xs={6}>
                <MainButton
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={handleExit}
                  style={{ height: 65 }}
                  disableElevation
                  disabled={!isInside}
                >
                  {t('exit')}
                </MainButton>
              </Grid>
            </Grid>

            {/* GDPR */}
            <Grid item xs={12} sm={9}>
              <Box fontWeight="fontWeightLight" color="grey.500">
                <Typography align="center" style={{ fontSize: theme.typography.pxToRem(10) }}>
                  <Trans i18nKey="gdprLink">
                    This text will be translated
                    <Link
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setDialog(Dialogs.TYPES.gdpr);
                      }}
                    >
                      Translated
                    </Link>
                  </Trans>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} container justify="space-between" spacing={1}>
              <Grid item xs={isXs ? 'auto' : 12} style={{ display: 'flex' }}>
                <Box fontWeight="fontWeightLight" color="grey.700" alignSelf="center" width={1}>
                  <Typography style={{ fontWeight: 'inherit' }} align={isXs ? 'left' : 'center'}>
                    <Link
                      target="_blank"
                      rel="noreferrer"
                      href={isExternal ? 'https://registrodevisitas.es' : undefined}
                      style={{ color: 'inherit' }}
                    >
                      www.registrodevisitas.es
                    </Link>
                  </Typography>
                </Box>
              </Grid>
              {isXs && (
                <Grid item xs="auto" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {printFlags()}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Dialogs.ExitOk open={dialog === Dialogs.TYPES.exitOk} onClose={closeDialog} />
        <Dialogs.Gdpr open={dialog === Dialogs.TYPES.gdpr} onClose={closeDialog} />
        <Dialogs.ExitNotAuthorised
          open={dialog === Dialogs.TYPES.notAuthorised}
          onClose={closeDialog}
          onAuthorized={onExitAuthorized}
          accessId={access && access.id}
        />
      </Container>

      {!isExternal && !isXs && (
        <Box
          position="absolute"
          top={0}
          right={0}
          width={300}
          height={1}
          style={{ backgroundColor: '#4b4b4b', paddingBottom: keyboard.height || 0 + 50 }} // TODO: align the QR with login input
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box display="flex" flexDirection="column" width={300} alignItems="center" px={2} height={1} paddingTop={1}>
            <Box display="flex" width={1} justifyContent="center" flexGrow={1} alignItems="flex-start">
              {printFlags()}
            </Box>
            <QRCode size={128} value={`${location.origin}${process.env.PUBLIC_URL}/visit?hash=${client.hash}`} />
            <Box
              marginBottom={4}
              marginTop={2}
              paddingX={1}
              color="secondary.contrastText"
              display="flex"
              justifyContent="center"
            >
              <Typography color="inherit" align="center">
                {t('qrHint')}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
